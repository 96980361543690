/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: ofin-webapp-legacy
 * Version: 7.0.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function (window, document) {

    /**
     * Animation d'un changement de valeur
     * Exemple : animate(console.log, 100, 200, 500);
     * @param {Function} callback fonction appelée avec la valeur courrante.
     * @param {number} from valeur de départ
     * @param {number} to valeur finale
     * @param {number} duration durée de l'animation
     * @param {Function} ease fonction qui décrit la façon dont la valeur évolue. Défaut : easeOutCubic.
     */
    function animate(callback, from, to, duration, ease) {
        duration = parseInt(duration, 10);
        from = parseFloat(from);
        to = parseFloat(to);
        var efunc = ease instanceof Function ? ease : function(currentTime, startValue, changeInValue, animationDuration) {
            currentTime /= animationDuration / 2;
            if (currentTime < 1) {
                return changeInValue / 2 * currentTime * currentTime * currentTime + startValue;
            }
            currentTime -= 2;
            return changeInValue / 2 * (currentTime * currentTime * currentTime + 2) + startValue;
        };
        var raf = (function(){
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                function (cb) {
                    // 60 = fps
                    window.setTimeout(cb, 1000 / 60);
                };
        })();
        var scale = function(x, a, b, min, max) {
            return ((b - a) * (x - min)) / (max - min) + a;
        };
        var start = +new Date;
        var routine = function() {
            var now = +new Date;
            var x = (now > start + duration) ? 1 : efunc(now - start, 0, 1, duration);
            var currentValue = scale(x, from, to, 0, 1);
            callback(currentValue);
            if (x < 1) {
                raf(routine);
            }
        };
        raf(routine);
    }

    function matches(el, selector) {
        if (!el) {
            return false;
        }
        var matchesCallable = (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector);
        if (!matchesCallable) {
            return false;
        }
        return matchesCallable.call(el, selector);
    }

    function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    }

    function parentMatches(el, selector) {
        return closest(el, function (e) {
            return matches(e, selector);
        }) != null;
    }

    var dataToggleAttr = 'data-toggle-odf'; // odf = offre de formation
    var dataToggleInstance = new DataToggle(
        {
            dataToggleElements: function () {
                return document.querySelectorAll('[' + dataToggleAttr + ']');
            },
            dataToggleSameElements: function (toggleExpression) {
                return document.querySelectorAll('[' + dataToggleAttr + '="' + toggleExpression + '"]');
            },
            dataToggleGroupElements: function (group) {
                return document.querySelectorAll('[' + dataToggleAttr + '-group="' + group + '"]');
            },
            target: function (element) {
                return element.getAttribute(dataToggleAttr);
            },
            events: function (element) {
                return element.getAttribute(dataToggleAttr + '-event');
            },
            groups: function (element) {
                return element.getAttribute(dataToggleAttr + '-group');
            },
            preventingCallback: function ($element, $target, $el) {
                return (
                    parentMatches($el, '.kTree') ||
                    parentMatches($el, '.ui-dialog') ||
                    parentMatches($el, '.plier-deplier') ||
                    parentMatches($el, '.kmultiselect-ltl') ||
                    (
                        parentMatches($el, '[data-toggle-odf-target]') &&
                        !matches($el, '[data-toggle]')
                    ) ||
                    matches($el, '[data-toggle-odf-target]')
                );
            },
            isBubble: function (element) {
                return element.hasAttribute('data-toggle-bubble') || matches(element, 'body.formation');
            }
        });
    [].forEach.call(document.querySelectorAll('[data-toggle-odf-event][aria-expanded]'), function (element) {
        var targets = document.querySelectorAll(element.getAttribute(dataToggleAttr));
        [].forEach.call(targets, function (el) {
            el.addEventListener('toggle-aria', function (event) {
                var status = event.detail[0];
                element.setAttribute('aria-expanded', status ? 'true' : 'false');
            });
        });
    });
    if (document.body.classList.contains('formation')) {
        dataToggleInstance.bind(document.body, '.cta.active', {});
    }

    window.addEventListener('load', function () {
        // Jumps fixes
        var jumpsPosition = (function getOffsetTop(elem) {
            var offsetTop = 0;
            if (elem) {
                do {
                    if (!isNaN(elem.offsetTop)) {
                        offsetTop += elem.offsetTop;
                    }
                } while (elem = elem.offsetParent);
            }
            return offsetTop;
        })(document.querySelector('#jumps'));
        window.addEventListener('scroll', function () {
            var actualPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (actualPosition > jumpsPosition) {
                document.body.classList.add('sticky-jumps');
            } else {
                document.body.classList.remove('sticky-jumps');
            }
        });
        // CTA Contact
        var $ctaContact = document.getElementById('cta-contact');
        const emailElement = document.getElementById('you');
        var contactSpamCount = 1;
        if ($ctaContact) {
            $ctaContact.querySelector('.retry').addEventListener('click', function () {
                document.dispatchEvent(new Event('formtimer:loadnew'));
                document.dispatchEvent(new Event('captcha:loadnew'));
                $ctaContact.querySelector('.confirmations').classList.remove('success');
                $ctaContact.querySelector('.confirmations').classList.remove('failed');
                $ctaContact.querySelector('.formulaire').style.display = 'block';
            });
            $ctaContact.addEventListener('submit', function (e) {
                if (emailElement && emailElement.validity.valid) {
                    e.preventDefault();
                    var query = function (method, url, params, success, error) {
                        params = (function (parameters) {
                            var members = [];
                            for (var p in parameters) {
                                if (parameters.hasOwnProperty(p)) {
                                    members.push(p + '=' + encodeURIComponent(parameters[p]));
                                }
                            }
                            return members.join('&');
                        })(params);
                        var xhr = new XMLHttpRequest();
                        xhr.addEventListener('readystatechange', function () {
                            if (xhr.readyState === 4 && xhr.status === 200) {
                                if (xhr.responseText.trim() === '') {
                                    success.apply(success, arguments);
                                    return;
                                }
                                error.apply(error, arguments);
                            }
                        });
                        xhr.open(method, url, false);
                        if (method.toUpperCase() === 'POST') {
                            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        }
                        // Better UX with small waiting time
                        window.setTimeout(function () {
                            xhr.send(params);
                        }, 2000);
                    };
                    $ctaContact.querySelector('.formulaire').style.display = 'none';
                    document.querySelector('#call-to-actions').classList.add('focus');
                    $ctaContact.querySelector('.wait').classList.add('waiting');
                    const inputElements = $ctaContact.getElementsByTagName('input');
                    let honeypotElementName;
                    let honeypotElementValue;
                    for (const element of inputElements) {
                        if (element.name.startsWith('email-')) {
                            honeypotElementName = element.name;
                            honeypotElementValue = element.value;
                        }
                    }
                    const params = {
                        you: $ctaContact.querySelector('[name=you]').value,
                        question: $ctaContact.querySelector('[name=question]').value,
                        CODE: $ctaContact.querySelector('[name=CODE]').value,
                        LANGUE: $ctaContact.querySelector('[name=LANGUE]').value,
                    };
                    if (honeypotElementName) {
                        params[honeypotElementName] = honeypotElementValue;
                    }
                    let formTimers = document.getElementsByName('formTimer');
                    if (formTimers.length > 0) {
                        params['formTimer'] = formTimers[0].value;
                    }
                    if ($ctaContact.querySelector('[name=cta-captcha]')) {
                        params.captcha = $ctaContact.querySelector('[name=cta-captcha]').value
                    }
                    query($ctaContact.getAttribute('method'), $ctaContact.getAttribute('action'), params, function success() {
                        contactSpamCount--;
                        if (contactSpamCount <= 0) {
                            $ctaContact.querySelector('.spam-prevention').style.display = 'block';
                        }
                        $ctaContact.querySelector('.wait').classList.remove('waiting');
                        $ctaContact.querySelector('.confirmations').classList.add('success');
                        window.setTimeout(function () {
                            document.querySelector('#call-to-actions').classList.remove('focus');
                        }, 7000);
                    }, function failed(response) {
                        if (!!response.currentTarget.responseText) {
                            $ctaContact.querySelector('.confirmations').querySelector('.failed').textContent = response.currentTarget.responseText;
                        }
                        $ctaContact.querySelector('.wait').classList.remove('waiting');
                        $ctaContact.querySelector('.confirmations').classList.add('failed');
                        window.setTimeout(function () {
                            document.querySelector('#call-to-actions').classList.remove('focus');
                        }, 7000);
                    });
                    return false;
                }
            });
        }

        // COUNTER
        function executeCounter(counter, value, duration) {
            value = value.replace(/,/g, '.').replace(/\s*/g, '');
            var digitsAfterComma = value.indexOf('.') >= 0 ? value.split('.')[1].length : 0;
            animate(function (x) {
                counter.innerHTML = x.toFixed(digitsAfterComma);
            }, 0, value, duration);
        }

        [].forEach.call(document.querySelectorAll('[data-counter]'), function (counter) {
            executeCounter(counter, counter.getAttribute('data-counter'), counter.getAttribute('data-counter-duration-ms'));
        });

        // RADIAL PROGRESS
        function executeRadial(radial, value, duration) {
            value = value.replace(/,/g, '.').replace(/\s*/g, '');
            animate(function (x) {
                var max = radial.querySelector('[stroke-dasharray]').getAttribute('stroke-dasharray');
                var offset = x * max / 100;
                radial.querySelector('[stroke-dashoffset]').setAttribute('stroke-dashoffset', offset);
            }, 0, value, duration);
        }

        [].forEach.call(document.querySelectorAll('[data-progress]'), function (progress) {
            executeRadial(progress, progress.getAttribute('data-progress'), progress.getAttribute('data-progress-duration-ms'));
        });
        [].forEach.call(document.querySelectorAll('[data-autoselect]'), function (autoselect) {
            autoselect.addEventListener(autoselect.getAttribute('data-autoselect') || 'click', function () {
                autoselect.select();
            });
        });
    });
})(window, document);
