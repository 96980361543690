/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: ofin-webapp-legacy
 * Version: 7.0.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function(document, window) {
    // polyfill custom event IE 9 - 10 > from https://developer.mozilla.org/fr/docs/Web/API/CustomEvent
    (function () {
        if (!window.hasOwnProperty('CustomEvent')) {
            var customEventPolyfill = function(event, params) {
                params = params || {bubbles: false, cancelable: false, detail: undefined};
                var evt = document.createEvent('CustomEvent');
                evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
                return evt;
            };
            customEventPolyfill.prototype = window.Event.prototype;
            window.CustomEvent = customEventPolyfill;
        }
    })();

    function query(selector, iter) {
        return queryOn(document, selector, iter);
    }

    function queryOn(parent, selector, iter) {
        if (!!iter) {
            return [].forEach.call(parent.querySelectorAll(selector), iter);
        } else {
            return parent.querySelector(selector);
        }
    }

    function indexOf(elements, el) {
        return [].indexOf.call(elements, el);
    }

    function isVisible(e) {
        return !!( e.offsetWidth || e.offsetHeight || e.getClientRects().length );
    }

    function dispatchOn(element, event, data) {
        var ev = new CustomEvent(event, data);
        element.dispatchEvent(ev);
    }

    var focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

    var modalVars = {
        classes: {
            main: 'modal',
            openState: 'opened',
            body: 'prevent-scroll-for-modal'
        },
        attributes: {
            main: 'data-modal',
            open: 'open',
            close: 'close'
        },
        events: {
            main: 'modal',
            open: 'open',
            close: 'close'
        }
    };

    function attrName() {
        [].unshift.call(arguments, modalVars.attributes.main);
        return [].join.call(arguments, '-');
    }

    function attrSelector() {
        return '[' + attrName.apply(attrName, arguments) + ']';
    }

    function attrSelectorValued() {
        var value = [].shift.call(arguments);
        return '[' + attrName.apply(attrName, arguments) + '=' + value + ']';
    }

    function eventName() {
        [].unshift.call(arguments, modalVars.events.main);
        return [].join.call(arguments, '.');
    }

    function bemStatusClass() {
        [].unshift.call(arguments, modalVars.classes.main);
        return [].join.call(arguments, '--');
    }

    function bemChildSelector() {
        [].unshift.call(arguments, modalVars.classes.main);
        return [].join.call(arguments, '__');
    }

    window.addEventListener('load', function() {
        query(attrSelector(), function(element) {
            var previousFocus = null;

            function trapKeys(ev) {
                if (ev.which === 27) { // ESC
                    dispatchOn(element, eventName(modalVars.events.close));
                } else if (ev.which === 9) { // TAB
                    var focusables = element.querySelectorAll(focusableElementsSelector);
                    var active = document.activeElement;
                    var index = indexOf(focusables, active);
                    var length = focusables.length;
                    if (ev.shiftKey) { // SHIFT + TAB
                        if (index === 0) {
                            focusables[length - 1].focus();
                            ev.preventDefault();
                        }
                    } else { // TAB
                        if (index === length - 1) {
                            focusables[0].focus();
                            ev.preventDefault();
                        }
                    }
                }
            }

            function focusOnFirstFocusable() {
                var first = null;
                queryOn(element, focusableElementsSelector, function(focusable) {
                    if (isVisible(focusable) && !first) {
                        first = focusable;
                    }
                });
                if (!!first) {
                    first.focus();
                }
            }

            function refocusPrevious() {
                if (!!previousFocus) {
                    previousFocus.focus();
                    previousFocus = null;
                }
            }

            element.addEventListener(eventName(modalVars.events.open), function() {
                previousFocus = document.activeElement;
                document.body.classList.add(modalVars.classes.body);
                element.classList.add(bemStatusClass(modalVars.classes.openState));
                element.setAttribute('aria-hidden', 'false');
                element.addEventListener('keydown', trapKeys);
                focusOnFirstFocusable();
            });

            element.addEventListener(eventName(modalVars.events.close), function() {
                element.classList.remove(bemStatusClass(modalVars.classes.openState));
                document.body.classList.remove(modalVars.classes.body);
                element.setAttribute('aria-hidden', 'true');
                refocusPrevious();
            });
        });

        query(attrSelector(modalVars.attributes.open), function(control) {
            control.addEventListener('click', function(ev) {
                ev.preventDefault();
                var target = control.getAttribute(attrName(modalVars.attributes.open));
                dispatchOn(query(attrSelectorValued(target)), eventName(modalVars.events.open));
            });
        });

        query(attrSelector(modalVars.attributes.close), function(control) {
            control.addEventListener('click', function(ev) {
                ev.preventDefault();
                var target = control.getAttribute(attrName(modalVars.attributes.close));
                dispatchOn(query(attrSelectorValued(target)), eventName(modalVars.events.close));
            });
        });
    });
})(document, window);
